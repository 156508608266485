export default {
  vertexShader: `
  varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,
  fragmentShader: `
  #include <common>
  uniform vec3 iResolution;
  uniform vec3 color1;
  uniform vec3 color2;
  uniform vec3 color3;
  uniform vec3 color4;
  uniform float rotation;
  uniform vec3 hexagonColor;
  uniform float iTime;

  varying vec2 vUv;

#define _Smooth(p,r,s) smoothstep(-s, s, p-(r))

#define S(a,b,t) smoothstep(a,b,t)

mat2 Rot(float a)
{
    float s = sin(a);
    float c = cos(a);
    return mat2(c, -s, s, c);
}


// Created by inigo quilez - iq/2014
// License Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
vec2 hash( vec2 p )
{
    p = vec2( dot(p,vec2(2127.1,81.17)), dot(p,vec2(1269.5,283.37)) );
return fract(sin(p)*43758.5453);
}

float noise( in vec2 p )
{
    vec2 i = floor( p );
    vec2 f = fract( p );

vec2 u = f*f*(3.0-2.0*f);

    float n = mix( mix( dot( -1.0+2.0*hash( i + vec2(0.0,0.0) ), f - vec2(0.0,0.0) ),
                        dot( -1.0+2.0*hash( i + vec2(1.0,0.0) ), f - vec2(1.0,0.0) ), u.x),
                   mix( dot( -1.0+2.0*hash( i + vec2(0.0,1.0) ), f - vec2(0.0,1.0) ),
                        dot( -1.0+2.0*hash( i + vec2(1.0,1.0) ), f - vec2(1.0,1.0) ), u.x), u.y);
return 0.5 + 0.5*n;
}

float smin( float a, float b, float k )
{
    float h = clamp( 0.5+0.5*(b-a)/k, 0.0, 1.0 );
    return mix( b, a, h ) - k*h*(1.0-h);
}

float smax(float a, float b, float k)
{
    return (-smin(-a,-b,k));
}

mat2 rotate(float r) {
    float c = cos(r);
    float s = sin(r);
    return mat2(c, -s, s, c);
}

float _Rad(float deg) {
    return PI / 180. * deg;
}

float df(vec2 pos)
{
    float borderRadius = 0.15;
    pos *= rotate(_Rad(rotation));

    vec2 q = abs(pos);

    return smax(smax((q.x * 0.866025 +pos.y*0.5),q.y,borderRadius),smax((q.x * 0.866025 -pos.y*0.5),q.y,borderRadius),borderRadius);
}

void mainImage( out vec4 fragColor, in vec2 fragCoord )
{
    vec2 uv = fragCoord.xy/iResolution.yy - vec2(0.5);
    float ratio = iResolution.x / iResolution.y;

    vec2 tuv = uv;
    tuv += vec2(0.5);
    tuv -= .5;
    tuv *= .5;

    // rotate with Noise
    float degree = noise(vec2(iTime*.1, tuv.x*tuv.y));

    tuv.y *= 1./ratio;
    tuv *= Rot(radians((degree-.5)*720.+180.));
    tuv.y *= ratio;

    float frequency = 5.;
    float amplitude = 30.;
    float speed = iTime * 2.;
    tuv.x += sin(tuv.y*frequency+speed)/amplitude;
    tuv.y += sin(tuv.x*frequency*1.5+speed)/(amplitude*.5);

    vec3 colorYellow = color1;
    vec3 colorDeepBlue = color2;
    vec3 layer1 = mix(colorYellow, colorDeepBlue, S(-.3, .2, (tuv*Rot(radians(-5.))).x));

    vec3 colorRed = color3;
    vec3 colorBlue = color4;
    vec3 layer2 = mix(colorRed, colorBlue, S(-.3, .2, (tuv*Rot(radians(-5.))).x));

    vec3 finalComp = mix(layer1, layer2, S(.5, -.3, tuv.y));

    // hex

    vec2 position = vec2(iResolution.x /iResolution.y * 0.5 - 0.5, .0);

    position = uv - position;

    vec3 hexCol = hexagonColor;

    float dist = 1. - df(position * 3.);

    float shape = 1.;

    shape *= _Smooth(dist,0.,.0001);

    hexCol *= shape;

    fragColor = vec4(finalComp, 1.0);
    fragColor = mix(fragColor, vec4(hexCol, 1.0), shape);
}

  void main() {
    mainImage(gl_FragColor, vUv * iResolution.xy);
  }
  `,
}
