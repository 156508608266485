<template>
  <div :class="$style.wrapper"><canvas ref="canvas" /></div>
</template>
<script>
import * as THREE from 'three'
import { TweenLite, Power2 } from 'gsap'

import shader from './shader'

const getColorVector = col => new THREE.Vector3(col.r, col.g, col.b)

const COLORS = {
  green: {
    color1: '#63CEB0',
    color2: '#A0FA5C',
    color3: '#AFFF4D',
    color4: '#63CEB0',
    hex: '#63CEB0',
  },
  blue: {
    color1: '#7863CE',
    color2: '#5CD4FA',
    color3: '#63C8CE',
    color4: '#4D54FF',
    hex: '#3EDCFF',
  },
  purple: {
    color1: '#637BCE',
    color2: '#FA5CCE',
    color3: '#CEBD63',
    color4: '#7F63CE',
    hex: '#6F3FF8',
  },
  purpleGreen: {
    color1: '#51EAFF',
    color2: '#77F962',
    color3: '#FF4DED',
    color4: '#5C6CFA',
    hex: '#77F962',
  },
  purpleBlue: {
    color1: '#16B9FF',
    color2: '#77F962',
    color3: '#FF4DED',
    color4: '#FF5170',
    hex: '#16B9FF',
  },
  blueRed: {
    color3: '#5C6CFA',
    color4: '#85CE63',
    color2: '#63CECE',
    color1: '#FF4D4D',
    hex: '#3FE1F8',
  },
}

export default {
  name: 'hexagon',
  props: {
    initialColor: {
      type: String,
      default: 'green',
    },
  },
  methods: {
    initCanvas() {
      const { initialColor } = this

      this.uniforms = {
        iTime: { value: 0 },
        iResolution: { value: new THREE.Vector3(1, 1, 1) },
        color1: { value: getColorVector(new THREE.Color(COLORS[initialColor].color1)) },
        color2: { value: getColorVector(new THREE.Color(COLORS[initialColor].color2)) },
        color3: { value: getColorVector(new THREE.Color(COLORS[initialColor].color3)) },
        color4: { value: getColorVector(new THREE.Color(COLORS[initialColor].color4)) },
        hexagonColor: { value: getColorVector(new THREE.Color(COLORS[initialColor].hex)) },
        rotation: { value: 90 },
      }

      const { canvas } = this.$refs
      const renderer = new THREE.WebGLRenderer({
        canvas,
      })

      renderer.setPixelRatio(1)
      renderer.autoClearColor = false

      const camera = new THREE.OrthographicCamera(
        -1, // left
        1, // right
        1, // top
        -1, // bottom
        -1, // near,
        1, // far
      )

      const scene = new THREE.Scene()
      const plane = new THREE.PlaneBufferGeometry(2, 2)
      const material = new THREE.ShaderMaterial({
        vertexShader: shader.vertexShader,
        fragmentShader: shader.fragmentShader,
        uniforms: this.uniforms,
      })

      scene.add(new THREE.Mesh(plane, material))

      this.renderer = renderer
      this.scene = scene
      this.camera = camera

      requestAnimationFrame(this.render)
    },

    resizeRendererToDisplaySize(renderer) {
      const canvas = renderer.domElement
      const width = canvas.clientWidth
      const height = canvas.clientHeight
      const needResize = canvas.width !== width || canvas.height !== height
      if (needResize) {
        renderer.setSize(width, height, false)

        this.uniforms.iResolution.value.x = width
        this.uniforms.iResolution.value.y = height
      }
      return needResize
    },

    render(time) {
      time *= 0.001
      this.uniforms.iTime.value = time
      this.resizeRendererToDisplaySize(this.renderer)

      this.renderer.render(this.scene, this.camera)

      requestAnimationFrame(this.render)
    },

    changeColor(color) {
      if (!COLORS[color]) return

      const col = COLORS[color]

      const duration = 1.2

      const color1 = getColorVector(new THREE.Color(col.color1))
      const color2 = getColorVector(new THREE.Color(col.color2))
      const color3 = getColorVector(new THREE.Color(col.color3))
      const color4 = getColorVector(new THREE.Color(col.color4))
      const hexColor = getColorVector(new THREE.Color(col.hex))

      TweenLite.to(this.uniforms.color1.value, { ...color1, ease: Power2.easeOut, duration })
      TweenLite.to(this.uniforms.color2.value, { ...color2, ease: Power2.easeOut, duration })
      TweenLite.to(this.uniforms.color3.value, { ...color3, ease: Power2.easeOut, duration })
      TweenLite.to(this.uniforms.color4.value, { ...color4, ease: Power2.easeOut, duration })
      TweenLite.to(this.uniforms.hexagonColor.value, { ...hexColor, ease: Power2.easeOut, duration })
    },

    rotate(deg) {
      const duration = 1.2

      TweenLite.to(this.uniforms.rotation, { value: deg, ease: Power2.easeOut, duration })
    },
  },

  computed: {
    color() {
      return 'green' // this.$store.state.hexColor
    },
    rotation() {
      return 90 // this.$store.state.hexRotation
    },
  },

  watch: {
    color(color) {
      this.changeColor(color)
    },
    rotation(rotation) {
      this.rotate(rotation)
    },
  },

  mounted() {
    this.initCanvas()
  },
}
</script>
<style lang="scss" module>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  canvas {
    width: 100%;
    height: 100%;
  }
}
</style>
